<template>
  <div class="text-center m-2">
    <Navbar />

    <p>PWA Installed: {{ $pwa.isPWAInstalled }}</p>
    <p>
      {{ $pwa?.showInstallPrompt }}
      {{ !$pwa?.offlineReady }}
      {{ !$pwa?.needRefresh }}
    </p>
    <p>built: {{ buildDate }}</p>
    <div
      v-if="!$pwa?.offlineReady && !$pwa?.needRefresh"
      class="pwa-toast"
      role="alert"
    >
      <div class="message">
        <span> Install PWA </span>
      </div>
      <button @click="$pwa.install()">Install</button>
      <button @click="$pwa.cancelInstall()">Cancel</button>
    </div>

    <slot />
  </div>
</template>

<style>
/* fix to make this work with smaller screens */
.p-toast {
  max-width: calc(100vw - 40px);
}
</style>

<script>
const buildDate = useAppConfig().buildDate;
onMounted(() => {
  console.log(`PWA INSTALLED: ${$pwa?.isPWAInstalled}`);

  $pwa.install();
});
</script>
