<template>
  <Menubar :model="items" class="mt-1 mb-3" breakpoint="4000px">
    <template #start>
      <!-- TODO space for logo is here  -->
    </template>
    <template #item="{ item, props, hasSubmenu }">
      <router-link
        v-if="item.link"
        v-slot="{ href, navigate }"
        :to="item.link"
        custom
      >
        <a v-ripple :href="href" v-bind="props.action" @click="navigate">
          <span :class="item.icon" />
          <span class="ml-2">{{ item.label }}</span>
        </a>
      </router-link>
      <a
        v-else
        v-ripple
        :href="item.url"
        :target="item.target"
        v-bind="props.action"
      >
        <span :class="item.icon" />
        <span class="ml-2">{{ item.label }}</span>
        <span v-if="hasSubmenu" class="pi pi-fw pi-angle-down ml-2" />
      </a>
    </template>

    <template #end>
      <div class="flex align-items-center gap-2">
        <!-- TODO add user info Avatar here -->
        <template v-if="usersStore.currentUser?.player?.booking_id">
          <!-- :image="usersStore.currentUser?.avatar" -->
          <Avatar
            :label="usersStore.currentUser?.player?.booking_id?.toString()"
            shape="circle"
            class="mr-2"
          />
        </template>
      </div>
    </template>
  </Menubar>
</template>

<script setup>
import { computed } from 'vue';
import { useRouter } from 'vue-router';

const router = useRouter();
const pb = usePocketBase();
const usersStore = useUsersStore();
const items = computed(() => [
  {
    label: 'home',
    icon: 'pi pi-home',
    link: '/',
  },
  {
    label: 'tournament',
    icon: 'pi pi-star',
    link: '/tournament',
  },
  // discgolf pages - course, tournament
  {
    label: 'discgolf',
    icon: 'pi pi-tag',
    items: [
      // overview
      {
        label: 'overview',
        icon: 'pi pi-chart-bar',
        link: '/discgolf',
      },
      {
        label: 'course',
        icon: 'pi pi-map',
        link: '/discgolf/course',
      },
      {
        label: 'tournament',
        icon: 'pi pi-calendar',
        link: '/discgolf/tournament',
      },
    ],
  },
  // Map
  {
    label: 'map',
    icon: 'pi pi-map',
    link: '/map',
  },
  // Schedule
  {
    label: 'schedule',
    icon: 'pi pi-calendar',
    items: [
      {
        label: 'timetable',
        icon: 'pi pi-calendar-clock',
        link: '/schedule/timetable',
      },
      {
        label: 'swiss draw',
        icon: 'pi pi-table',
        link: '/schedule/swiss-draw',
      },
    ],
  },
  // Booking
  {
    label: 'booking',
    icon: 'pi pi-shopping-cart',
    link: '/booking',
    visible:
      !!usersStore.currentUser?.roles?.includes('booker') ||
      !!usersStore.currentUser?.roles?.includes('admin'),
  },
  // Login
  {
    label: 'login',
    icon: 'pi pi-sign-in',
    link: '/user/login',
    visible: !usersStore.currentUser,
  },
  // User
  {
    label: 'me',
    icon: 'pi pi-user',
    visible: !!usersStore.currentUser,
    items: [
      {
        label: 'profile',
        icon: 'pi pi-user',
        link: '/user/profile',
      },
      {
        label: 'logout',
        icon: 'pi pi-sign-out',
        link: '/',
        command: () => {
          pb.authStore.clear();
          usersStore.refreshCurrentUser();
          location.reload();
          router.push('/');
        },
      },
    ],
  },
  {
    label: 'admin',
    icon: 'pi pi-lock',
    visible: !!usersStore.currentUser?.roles?.includes('admin'),
    link: '/admin',
  },

  {
    label: 'teammanager',
    icon: 'pi pi-crown',
    visible: !!usersStore.currentUser?.roles?.includes('teammanager'),
    items: [
      {
        label: 'overview',
        icon: 'pi pi-chart-bar',
        link: `/teammanager`,
      },
      {
        label: 'players',
        icon: 'pi pi-server',
        link: `/teammanager/players`,
      },
      {
        label: 'new spirit',
        icon: 'pi pi-heart',
        link: `/new-spirit`,
      },
      {
        label: 'spirit overview',
        icon: 'pi pi-pencil',
        link: `/teammanager/spirit-overview`,
      },
      {
        label: 'settlement',
        icon: 'pi pi-money-bill',
        link: `/teammanager/settlement`,
      },
      {
        separator: true,
      },
    ],
  },
  {
    label: 'stats',
    icon: 'pi pi-chart-bar',
    visible: !!usersStore.currentUser?.roles?.includes('stats'),
    items: [
      {
        label: 'Bierzelt',
        icon: 'pi pi-beer',
        link: '/stats?name=bierzelt',
      },
    ],
  },
  // al pages - overview, helferlist, saladlist only for admin and worker
  {
    label: 'ars ludendi internal',
    icon: 'pi pi-crown',
    visible:
      !!usersStore.currentUser?.roles?.includes('worker') ||
      !!usersStore.currentUser?.roles?.includes('admin'),
    items: [
      {
        label: 'overview',
        icon: 'pi pi-chart-bar',
        link: `/al`,
      },
      {
        label: 'helferlist',
        icon: 'pi pi-server',
        link: `/al/helperjobs`,
      },
      {
        label: 'foods',
        icon: 'pi pi-pencil',
        link: `/al/foods`,
      },
      {
        separator: true,
      },
    ],
  },
  {
    label: 'settings',
    icon: 'pi pi-cog',
    link: '/settings',
  },
]);
</script>
